









import { Vue, Component } from "vue-property-decorator";
import NoticeHome from "@/store/modules/noticeHome";
import Msgchat from "@/components/UIComponents/Msgchat.vue";

import Navbar from "@/components/NewUIComponents/navbar.vue";
import Footer from "@/components/NewUIComponents/footer.vue";
import Login from "@/newViews/newAuthscreen/loginModal.vue";

@Component({
  components: {
    Navbar,
    Footer,
    Login,
    Msgchat,
  },
})
export default class NewPublicLayout extends Vue {
  async created() {
    const previousLayout = localStorage.getItem("previousLayout");
    if (previousLayout && previousLayout !== "NewPublicLayout") {
      window.location.reload();
    }
    localStorage.setItem("previousLayout", "NewPublicLayout");
    await NoticeHome.getNotice();
  }
}
