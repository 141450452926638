






















































































import { Vue, Component } from "vue-property-decorator";
import { AdminRouter, RootRouter } from "@/utils/routePathContsant";

@Component({})
export default class Footer extends Vue {
  get RootRouter() {
    return RootRouter;
  }
}
// export default{
//     name: 'footer'
// }
