




















































































































































import { Vue, Component } from "vue-property-decorator";
import { JWTModule, MessagingModule, CommonStore } from "@/store/modules";
import { AdminRouter } from "@/utils/routePathContsant";
import { eventHub } from "@/utils/eventHub";

import * as NewRootRouter from "@/utils/newRoutePathConstant";

@Component({})
export default class Navbar extends Vue {
  // isNewMsg: boolean = false;
  displayChathead: boolean = true;
  // newalertmessage: string = "new message";

  // async created() {
  //   this.isNewMsg = MessagingModule.newChatCount;
  //   eventHub.$on("show-chat-head", this.show);
  // }

  // show() {
  //   this.displayChathead = CommonStore.isChatHeadActive;
  // }

  get isLoggedIn() {
    return JWTModule.isLoggedIn;
  }

  get loginDialog() {
    return CommonStore.loginDialog;
  }

  set loginDialog(visible: boolean) {
    CommonStore.setLoginDialog(visible);
  }

  get userDetail() {
    return JWTModule.UserDetail;
  }
  get AdminRouter() {
    return AdminRouter;
  }

  get NewRootRouter() {
    return NewRootRouter;
  }

  // get NewMsg() {
  //   let new_msg = CommonStore.isNewMsg;
  //   if (new_msg === true) {
  //     this.newalertmessage = "new message";
  //   } else {
  //     this.newalertmessage = "";
  //   }
  //   return CommonStore.isNewMsg;
  // }

  handleLogin() {
    this.$confirm("Login")
      .then((_) => {
        this.$router.push(NewRootRouter.RootRouter.Root);
      })
      .catch((_) => {});
  }

  onLoginDialogClose() {
    this.$validator.reset();
  }

  async onLogoutItemClickConfirm(e: any) {
    await JWTModule.clearJWT();
    this.$router.push({ name: "NewHome" });
    window.location.reload();
  }

  // async onMessageButtonClicked(e: any) {
  //   this.newalertmessage = "";
  //   // await MessagingModule.selectUser({ userId: e, page: 1 });
  //   CommonStore.setChatHead(false);
  //   this.displayChathead = CommonStore.isChatHeadActive;
  //   eventHub.$emit("show-message-box", e);
  // }
}
