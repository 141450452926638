




































































































import { Vue, Component } from "vue-property-decorator";
import FacebookLoginButton from "@/components/UIComponents/FacebookLoginButton.vue";
import GoogleLoginButton from "@/components/UIComponents/GoogleLoginButton.vue";
import CaptchaNew from "@/components/NewUIComponents/captcha.vue";
import { RootRouter } from "@/utils/newRoutePathConstant";
import * as OldRouter from "@/utils/routePathContsant";
import { SnotifyPosition } from "vue-snotify";
import authStore from "@/store/modules/auth";
import { JWTModule } from "@/store/modules";
import helpers from "../../utils/helpers";
import CaptchaModule from "@/store/modules/captcha";
import { Captcha } from "@/store/models/captcha";
import { eventHub } from "@/utils/eventHub";

@Component({
  components: {
    FacebookLoginButton,
    GoogleLoginButton,
    CaptchaNew,
  },
})
export default class Login extends Vue {
  $refs!: {
    captcha: InstanceType<typeof CaptchaNew>;
  };

  userPassword: string = "";
  isValidated: boolean = false;
  captchaValid: boolean = true;
  captcha_obj: Captcha = {} as Captcha;
  userName: string = "";
  isLoginActive = false;
  isVisible = false;

  get OldRouter() {
    return OldRouter;
  }
  get RootRouter() {
    return RootRouter;
  }
  get isLoggedIn() {
    return JWTModule.isLoggedIn;
  }

  resetCaptchaValidation() {
    this.captchaValid = true;
  }

  checkCaptchaInput(input_value: string, captcha_id: number) {
    this.captcha_obj.id = captcha_id;
    this.captcha_obj.textFromUser = input_value;
    return this.captcha_obj;
  }

  redirect() {
    let profile = helpers.getUserProfile() as any;
    if (profile && profile.id) {
      if (profile.userType && profile.userType != "public") {
        this.$router.push(OldRouter.AdminRouter.Root);
      } else {
        this.$bvModal.hide("login");
        this.$router.push(RootRouter.NewHome);
      }
    } else {
      eventHub.$emit("clear-captcha-input-field", true);
    }
  }

  async onSubmit(e: any) {
    let isValid = await this.$validator.validateAll("login");
    let valid = await this.$refs.captcha.$validator.validateAll("login");
    if (isValid && valid) {
      if (
        this.captcha_obj.textFromUser &&
        this.captcha_obj.textFromUser != ""
      ) {
        this.captchaValid = true;
        const response = await CaptchaModule.validateCaptcha(this.captcha_obj);
        this.isValidated = response.validated;
        this.captchaValid = response.validated;
      }
    }

    if (!this.isValidated) {
      eventHub.$emit("clear-captcha-input-field");
    }

    if (this.isValidated) {
      this.isLoginActive = true;
      await authStore.loginUser({
        username: this.userName,
        password: this.userPassword,
        source: "web",
      });
      this.redirect();
    }
    this.isLoginActive = false;
  }

  enterLogin(e: any) {
    this.onSubmit(e);
  }

  redirectToSignup() {
    this.$router.push(RootRouter.UserRegister);
    this.$bvModal.hide("login");
  }

  onForgotPassword() {
    this.$bvModal.hide("login");
  }

  async resetModal() {
    await CaptchaModule.getCaptcha();
  }
}
